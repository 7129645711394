export function createHandleProxy() {
    return new Proxy({
        current: null
    }, {
        get (target, p, receiver) {
            if ("current" === p) return target.current;
            if (p in target) return Reflect.get(target, p, receiver);
            if (!target.current) throw Error("Handle not mounted");
            return Reflect.get(target.current, p, receiver);
        },
        set (target, p, value, receiver) {
            if ("current" === p) return target.current = value, !0;
            if (!target.current) throw Error("Handle not mounted");
            return Reflect.set(target.current, p, value, receiver);
        }
    });
}
