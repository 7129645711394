import { gql } from "$gql";
export const GET_ALL_MY_SECTOR0X_CHARACTERS = gql("query GetAllMySector0xCharacters {\n  sector0x {\n    characters {\n      id\n      description\n    }\n  }\n}");
export const GET_SECTOR0X_CHARACTER_BY_ID = gql("query GetSector0xCharacterById($id: uuid!) {\n  sector0x {\n    characters_by_pk(id: $id) {\n      id\n      description\n    }\n  }\n}");
export const GET_MY_DEFAULT_SECTOR0X_CHARACTER = gql("query GetMyDefaultCharacter($id: String!) {\n  sector0x {\n    user_settings_by_pk(id: $id) {\n      default_character\n    }\n  }\n}");
export const GET_ALL_MY_CHARACTERS = gql("query GetAllMyCharacters {\n  user_character(where: {owned: {_eq: true}}, order_by: {updated_at: desc}) {\n    id\n    reference_id\n    description\n    private_config: private_config_view\n    attributes\n    equipments {\n      slot\n      item_id\n      count\n      data\n    }\n  }\n  sector0x {\n    characters {\n      id\n      description\n    }\n  }\n}");
export const UPSERT_USER_CHARACTER = gql("mutation UpsertUserCharacter($id: uuid!, $description: jsonb!, $reference_id: uuid, $private_config: jsonb) {\n  insert_user_character_one(object: {id: $id, description: $description, reference_id: $reference_id, private_config: $private_config}, on_conflict: {constraint: character_pkey, update_columns: [description, private_config]}) {\n    id\n  }\n}");
export const GET_SEASON_TICKETS = gql("query GetSeasonTickets($id: bpchar!) {\n  season_ticket(where: {season_id: {_eq: $id}}, order_by: [{claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n    id: ticket_id\n    description\n    character {\n      id\n      description\n    }\n  }\n}");
export const GET_PARTIAL_SEASON_INFO = gql("query GetPartialSeasonInfo($id: bpchar!) {\n  season_by_pk(id: $id) {\n    world_id\n    tickets(order_by: [{team: asc}, {claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n      id: ticket_id\n      description\n      private_config: private_config_view\n      character {\n        id\n        description\n        owner\n      }\n      team\n    }\n    waitlist(order_by: {created_at: desc}) {\n      character_id\n      description: config\n      private_config: private_config_view\n      info\n    }\n  }\n}");
export const UPDATE_MY_TICKET = gql("mutation UpdateMyTicket($ticket_id: bpchar!, $season_id: bpchar!, $config: jsonb!, $private_config: jsonb!) {\n  update_season_ticket_by_pk(_set: {config: $config, private_config: $private_config}, pk_columns: {season_id: $season_id, ticket_id: $ticket_id}) {\n    ticket_id\n  }\n}");
export const GENERATE_COUPON = gql('query GenerateCoupon($id: String = "") {\n  generate_coupon(args: {id: $id}) {\n    id\n    expired_at\n    used_at\n  }\n}');
export const GET_PRIVATE_WORLD = gql("query GetPrivateWorld {\n  private_world_list {\n    id\n    basic_info\n    enabled\n    created_at\n    channel { id }\n  }\n}");
export const GET_PRIVATE_SEASON = gql("query GetPrivateSeason {\n  private_season_list {\n    id\n    basic_info\n    created_at\n    start_at\n    ticket_count\n    ticket_remain\n    custom {\n      starter\n      config\n      discount\n    }\n  }\n}");
export const SUBSCRIBE_WORLD_CAPTION = gql('subscription SubscribeWorldCaption($id: bpchar!) {\n  world_by_pk(id: $id) {\n    caption: basic_info(path: "caption")\n  }\n}');
export const GET_PLAYER_ID_LIST = gql('query GetPlayerIdList($world: bpchar = "") {\n  player(where: {world_id: {_eq: $world}, enabled: {_eq: true}}) {\n    id\n    team\n    character_id\n  }\n}');
export const GET_PLAYER_INFO = gql('query GetPlayerInfo($id: bpchar!) {\n  player: player_by_pk(id: $id) {\n    id\n    name: data(path: "name")\n    pron: data(path: "pron")\n    memories: data(path: "memories")\n    textures: data(path: "textures")\n    character_id\n  }\n}');
export const GET_PLAYER_STATE = gql("query QueryPlayerState($player: bpchar!) {\n  player_by_pk(id: $player) {\n    id\n    action\n    plan\n  }\n}");
export const SUBSCRIBE_PLAYER_STATE = gql("subscription SubscribePlayerState($player: bpchar!) {\n  player_by_pk(id: $player) {\n    id\n    action\n    plan\n  }\n}");
export const GET_LATEST_CONVERSATION = gql('query QueryLatestConversation($player: bpchar!) {\n  conversation(limit: 100, order_by: {created_at: desc}, where: {participants: {player_id: {_eq: $player}}}) {\n    id\n    created_at\n    participants {\n      player {\n        id\n        name: data(path: "name")\n      }\n    }\n    data\n  }\n}');
export const SUBSCRIBE_LATEST_CONVERSATION = gql('subscription SubscribeLatestConversation($player: bpchar!) {\n  conversation(limit: 100, order_by: {created_at: desc}, where: {participants: {player_id: {_eq: $player}}}) {\n    id\n    created_at\n    participants {\n      player {\n        id\n        name: data(path: "name")\n      }\n    }\n    data\n  }\n}');
export const GET_CONVERSATION_LOG = gql("query GetChatHistory($conversation_id: bpchar!) {\n  conversation_log(where: {conversation_id: {_eq: $conversation_id}}, order_by: {created_at: asc}) {\n    id\n    data\n    created_at\n  }\n}");
export const GET_CONVERSATION_LOG_STREAM = gql("subscription GetChatHistoryStream($conversation_id: bpchar!, $id: Int!) {\n  conversation_log_stream(batch_size: 10, cursor: {initial_value: {id: $id}, ordering: ASC}, where: {conversation_id: {_eq: $conversation_id}}) {\n    id\n    data\n    created_at\n  }\n}");
export const GET_PLAYER_ID_FROM_CHARACTER_ID = gql("query GetPlayerIdFromCharacterId($world: bpchar!, $character: uuid!) {\n  player(where: {world_id: {_eq: $world}, character_id: {_eq: $character}}) {\n    id\n  }\n}");
export const GET_PLAYER_DATA = gql('query GetPlayerData($id: bpchar!) {\n  player: player_by_pk(id: $id) {\n    id\n    data\n    character {\n      equipments(where: {slot: {_eq: "bubble"}}) {\n        item {\n          description\n        }\n        data\n      }\n    }\n    team\n  }\n}');
export const GET_CURRENT_CABIN = gql("query GetCurrentCabin {\n  current_cabin {\n    id\n    config: merged_config\n  }\n}");
export const GET_CABIN = gql("query GetCabin($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    id\n    merged_config\n    user_id\n    template_id\n  }\n  cabin_channel(args: {id: $id}) {\n    id\n  }\n}");
export const SUBSCRIBE_CABIN = gql("subscription SubscribeCabin($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    id\n    merged_config\n    user_id\n    template_id\n  }\n}");
export const GET_CABIN_CHARACTER = gql("query GetCabinCharacter($id: bpchar!) {\n  cabin_character(where: {_and: [{cabin_id: {_eq: $id}}, {character: {owned: {_eq: true}}}]}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n  }\n}");
export const GET_MY_CHARACTER_IN_CABIN = gql("query GetMyCharacterInCabin($owner: String!) {\n  cabin_character(where: {character: {owner: {_eq: $owner}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    cabin {\n      id\n      owned\n    }\n    enabled\n  }\n}");
export const SUBSCRIBE_MY_CHARACTER_IN_CABIN = gql("subscription SubscribeMyCharacterInCabin($owner: String!) {\n  cabin_character(where: {character: {owner: {_eq: $owner}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    cabin {\n      id\n      owned\n    }\n    enabled\n  }\n}");
export const SUBSCRIBE_CABIN_WATING_ROOM = gql("subscription SubscribeCabinWaitingRoom($id: bpchar!) {\n  cabin_character(where: {cabin_id: {_eq: $id}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    enabled\n    character {\n      id\n      owner\n      owned\n    }\n  }\n}");
export const SUBSCRIBE_CABIN_WATING_ROOM_GUEST = gql("subscription SubscribeCabinWaitingRoomGuest($id: bpchar!) {\n  cabin_character(where: {cabin_id: {_eq: $id}, character: {owned: {_eq: false}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    enabled\n    character {\n      id\n      owner\n    }\n  }\n}");
export const UPDATE_CABIN_CHARACTER_LIST = gql("mutation UpdateCabinCharacterList($cabin_id: String!, $character_list: jsonb!) {\n  update_cabin_character_list(args: {cabin_id: $cabin_id, character_list: $character_list}) {\n    cabin_id\n    character_id\n    joined_at\n  }\n}");
export const RECALL_CABIN_CHARACTER = gql("mutation RecallCabinCharacter($cabin_id: bpchar!, $character_id: uuid!) {\n  delete_cabin_character_by_pk(cabin_id: $cabin_id, character_id: $character_id) {\n    cabin_id\n    character_id\n  }\n}");
export const CLOSE_CABIN_WAITING_LIST = gql("mutation CloseCabinWaitingList($cabin_id: bpchar!) {\n  delete_cabin_character(where: {cabin_id: {_eq: $cabin_id}, character: {owned: {_eq: false}}}) {\n    affected_rows\n  }\n  delete_cabin_code_by_pk(cabin_id: $cabin_id) {\n    code\n  }\n}");
export const PUBLIC_CABIN_WAITING_LIST = gql("mutation PublicCabinWaitingList($cabin_id: bpchar!) {\n  update_cabin_code_by_pk(pk_columns: {cabin_id: $cabin_id}, _set: {is_public: true}) {\n    code\n  }\n}");
export const PRIVATE_CABIN_WAITING_LIST = gql("mutation PrivateCabinWaitingList($cabin_id: bpchar!) {\n  update_cabin_code_by_pk(pk_columns: {cabin_id: $cabin_id}, _set: {is_public: false}) {\n    code\n  }\n}");
export const GET_PUBLIC_CABIN = gql("query GetPublicCabin($cabin_id: bpchar!) {\n  cabin_code_by_pk(cabin_id: $cabin_id) {\n    is_public\n  }\n}");
export const LIST_PUBLIC_CABIN = gql("query ListPublicCabin($limit: Int = 100) {\n  cabin_code(where: {is_public: {_eq: true}}, limit: $limit, order_by: {created_at: desc}) {\n    cabin {\n      id\n      basic_info\n      owned\n    }\n  }\n}");
export const SUBSCRIPT_PUBLIC_CABIN_HEAD = gql("subscription SubscriptPublicCabinHead {\n  cabin_code(where: {is_public: {_eq: true}}, limit: 1) {\n    cabin {\n      id\n      owned\n    }\n  }\n}");
export const GET_CHARATER = gql("query GetCharacter($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    id\n    description\n    private_config: private_config_view\n    owner\n    equipments {\n      item {\n        id\n        description\n      }\n      slot\n      data\n      count\n    }\n  }\n}");
export const QUERY_LATEST_CABIN_LOG = gql("query QueryLatestCabinLog($id: uuid!) {\n  cabin_log(where: {participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}, limit: 1) {\n    id\n    data\n    summary\n  }\n}");
export const SUBSCRIBE_LATEST_CABIN_LOG = gql("subscription SubscribeLatestCabinLog($id: uuid!) {\n  cabin_log(where: {participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}, limit: 1) {\n    id\n    data\n    summary\n  }\n}");
export const QUERY_CABIN_LOG = gql('query QueryCabinLog($before: timestamptz!, $id: uuid!) {\n  cabin_log(limit: 10, where: {created_at: {_lt: $before}, participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}) {\n    id\n    data\n    summary\n    created_at\n    participants {\n      character {\n        id\n        name: description(path: "name")\n      }\n    }\n  }\n}');
export const SUBSCRIBE_CABIN_ENERGY_STATE = gql("subscription SubscribeCabinEnergyState($user_id: String!) {\n  cabin_energy_by_pk(user_id: $user_id) {\n    energy\n    premium_energy\n  }\n}");
export const INSERT_MESSAGE = gql("mutation InsertMessage($channel: uuid!, $msg: jsonb!, $user_id: String!, $author_id: uuid!) {\n  insert_messages_one(object: {author_id: $author_id, channel_id: $channel, message: $msg, user_id: $user_id}) {\n    id\n  }\n}");
export const GET_MESSAGES_STREAMING_SUBSCRIPTION = gql("subscription GetMessagesStreamingSubscription($channel: uuid!, $cursor: bigint = 0, $batch_size: Int = 20) {\n  messages_stream(batch_size: $batch_size, cursor: {initial_value: {id: $cursor}}, where: {channel_id: {_eq: $channel}}) {\n    id\n    message\n    user_id\n    created_at\n    author_id\n  }\n}");
export const GET_MESSAGES = gql("query GetOldMessages($channel: uuid!, $offset: Int = 0, $limit: Int = 20) {\n  messages(where: {channel_id: {_eq: $channel}}, limit: $limit, offset: $offset, order_by: {id: desc}) {\n    id\n    message\n    user_id\n    created_at\n    author_id\n  }\n}");
export const GET_CABIN_CODE = gql("query SubscribeCabinCode($cabin_id: bpchar!) {\n  cabin_code_by_pk(cabin_id: $cabin_id) {\n    code\n    expired_at\n    is_public\n  }\n}");
export const GENERATE_CABIN_CODE = gql("mutation GenerateCabinCode($cabin_id: String!) {\n  generate_cabin_code(args: {cabin_id: $cabin_id}) {\n    code\n  }\n}");
export const RESOLVE_CABIN_CODE = gql("query ResolveCabinCode($code: String!) {\n  resolve_cabin_code(args: {code: $code}) {\n    id\n    owned\n  }\n}");
export const UPDATE_CABIN_INFO = gql("mutation UpdateCabinInfo($cabin_id: String!, $value: jsonb!) {\n  update_cabin_info(args: {cabin_id: $cabin_id, value: $value}) {\n    id\n    merged_config\n  }\n}");
export const UPDATE_CABIN_WAITING_LIST = gql("mutation UpdateCabinWaitingList($cabin_id: String!, $character_list: jsonb!) {\n  update_cabin_waiting_list(args: {cabin_id: $cabin_id, character_list: $character_list}) {\n    cabin_id\n    character_id\n  }\n}");
export const GET_PREMIUM_STATUS = gql("query GetPremiumStatus($user_id: String!) {\n  user_premium_by_pk(user_id: $user_id) {\n    begin_at\n    duration\n    end_at\n    expired\n    type\n  }\n}");
export const REFILL_CABIN_PREMIUM_ENERGY = gql("mutation RefillCabinPremiumEnergy {\n  refill_cabin_premium_energy {\n    premium_energy\n  }\n}");
export const QUERY_CABIN_TEMPLATE_STORE = gql('query QueryCabinTemplateStore {\n  cabin_template(order_by: [{owned: asc}, {created_at: desc}], where: {enabled: {_eq: true}, type: {_eq: "store"}}) {\n    template_id\n    info\n    data\n    created_at\n    owned\n  }\n}');
export const QUERY_CABIN_TEMPLATE_LIST = gql('query QueryCabinTemplateList {\n  cabin_template(order_by: {created_at: desc}, where: {enabled: {_eq: true}, type: {_neq: "store"}}) {\n    template_id\n    type\n    info\n    created_at\n  }\n  cabin_template_owned(order_by: {created_at: desc}) {\n    template_id\n    info\n    created_at\n    description\n  }\n}');
export const CABIN_CHOOSE_THEME = gql("mutation CabinChooseTheme($cabin_id: bpchar!, $template_id: bpchar!) {\n  cabin_choose_theme(args: {cabin_id: $cabin_id, template_id: $template_id}) {\n    updated_at\n  }\n}");
export const GET_LIMITED_THEMES = gql('query GetLimitedThemes {\n  cabin_template(where: {enabled: {_eq: true}, type: {_eq: "limited"}}) {\n    template_id\n    info\n  }\n}');
export const GET_EXPORTED_WORLD_TEMPLATE = gql("query GetExportedWorldTemplate {\n  exported_world_template {\n    id\n    config\n  }\n}");
export const UPDATE_SEASON_CONFIG = gql("mutation UpdateSeasonConfig($season_id: String!, $new_config: jsonb!) {\n  update_season_config(args: {season_id: $season_id, new_config: $new_config}) {\n    id\n  }\n}");
export const UPDATE_WORLD_CONFIG = gql("mutation UpdateWorldConfigUser($season_id: String!, $new_config: jsonb!) {\n  update_world_config(args: {season_id: $season_id, new_config: $new_config}) {\n    id\n  }\n}");
export const GET_MY_SEASON = gql("query GetMySeason {\n  season(where: {active: {_eq: true}, custom: {owned: {_eq: true}}}, order_by: {start_at: asc}) {\n    id\n    start_at\n    end_at\n    ticket_count\n    ticket_remain\n    basic_info\n    visible\n    custom {\n      starter\n      config\n      discount\n    }\n    recruit {\n      end_at\n      min_player_count\n    }\n  }\n}");
export const SUBSCRIBE_SEASON_CUSTOM = gql("subscription SuibscribeSeasonCustom($id: bpchar!) {\n  season_custom_by_pk(id: $id) {\n    owned\n    config\n  }\n}");
export const QUERY_USER_TOKENS = gql("query QueryUserToken($user_id: String!) {\n  user_token_by_pk(user_id: $user_id) {\n    tokens\n  }\n}");
export const QUERY_USER_BALANCE = gql("query QueryUserBalance {\n  sector0x {\n    my_balance {\n      balance\n    }\n  }\n}");
export const ACQUIRE_CABIN_TEMPLATE_FROM_STORE = gql("mutation AcquireCabinTemplateFromStore($template_id: String!) {\n  acquire_cabin_template_from_store(args: {template_id: $template_id}) {\n    info\n  }\n}");
export const QUERY_UNCLAIMED_SEASON_BONUS = gql("query QueryUnclaimedSeasonBonus($user: String!) {\n  season_bonus(where: {owner: {_eq: $user}, claimed_at: {_is_null: true}}) {\n    value\n    season_id\n    world_id\n  }\n}");
export const QUERY_SEASON_BONUS = gql("query QuerySeasonBonus($id: bpchar!) {\n  season_bonus(where: { world_id: {_eq: $id} }) {\n    season_id\n    value\n    claimed_at\n  }\n}");
export const CLAIM_SEASON_BONUS = gql("mutation ClaimSeasonBonus($season_id: String!) {\n  claim_season_bonus(args: {season_id: $season_id}) {\n    season_id\n    value\n    claimed_at\n  }\n}");
export const GET_MISSION_LIST = gql("query GetMissionList {\n  mission_sort(order_by: {sort_order: asc}) {\n    mission {\n      id\n      description\n    }\n    type\n  }\n}");
export const GET_MISSION_INSTANCE_INFO = gql("query GetMissionInstanceInfo($id: bpchar!) {\n  mission_instance_by_pk(id: $id) {\n    data\n    definition {\n      id\n      description\n    }\n    canceled_at\n    result {\n      data\n      claimed_at\n      created_at\n      finished_at\n    }\n  }\n}");
export const GET_ACTIVE_MISSIONS = gql("query GetActiveMissions {\n  mission_instance(where: {_or: [{_not: {result: {id: {_is_null: false}}}}, {result: {finished: {_eq: false}}}, {result: {claimed_at: {_is_null: true}}}], canceled_at: {_is_null: true}}) {\n    id\n    data\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n    result {\n      finished_at\n    }\n  }\n}");
export const SUBSCRIBE_ACTIVE_MISSIONS = gql("subscription SubscribeActiveMissions {\n  mission_instance(where: {_or:[{_not:{result:{id:{_is_null:false}}}},{result:{finished:{_eq:false}}},{result:{claimed_at:{_is_null:true}}}]}) {\n    id\n    data\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n    result {\n      finished_at\n    }\n  }\n}");
export const GET_MY_MISSION_HISTORY = gql("query GetMyMissionHistory($where: mission_instance_bool_exp!) {\n  mission_instance(where: $where, order_by: {created_at: desc}, limit: 20) {\n    id\n    data\n    created_at\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n  }\n}");
export const GET_ITEM_DEFINITION = gql("query GetItemDefinition($id: String!) {\n  item_definition_by_pk(id: $id) {\n    description\n  }\n}");
export const CLAIM_MISSION_RESULT_REWARDS = gql("mutation ClaimMissionResultRewards($id: bpchar!) {\n  claim_mission_result_rewards(args: {instance_id: $id}) {\n    claimed_at\n  }\n}");
export const GET_MY_ITEMS = gql("query getMyItems {\n  user_item {\n    item_id\n    count\n  }\n}");
export const GET_CHARACTER_IN_MISSION = gql("query getCharacterInMission {\n  user_character_in_mission {\n    id\n  }\n}");
export const GET_SUPPLY_LIST = gql("query GetSupplyList {\n  supply {\n    id\n    price\n    refill_cycle\n    data\n    count\n    count_key\n  }\n}");
export const QUERY_SUPPLY_LOG_COUNT = gql("query QuerySupplyLogCount($id: String!, $count_key: String!) {\n  supply_log_aggregate(where: {supply_id: {_eq: $id}, count_key: {_eq: $count_key}}) {\n    aggregate {\n      count\n    }\n  }\n}");
export const USE_SUPPLY = gql("mutation UseSupply($supply_id: String!) {\n  use_supply(args: {supply_id: $supply_id}) {\n    transaction_id\n  }\n}");
export const CURRENT_MISSION_INSTANCE_COUNT = gql("query CurrentMissionInstanceCount {\n  current_mission_instance_count {\n    count\n  }\n}");
export const GET_CONFIG = gql("query GetConfigByKey($key: String!) {\n  config_by_pk(key: $key) {\n    value\n  }\n}");
export const CANCEL_MISSION = gql("mutation CancelMission($id: bpchar!) {\n  cancel_mission_instance(args: {id: $id}) {\n    id\n  }\n}");
export const LIST_CUSTOMIZE_STORE = gql("query ListCustomizeStore {\n  customize_store(order_by: {order: asc}, where: {order: {_is_null: false}}) {\n    id\n    data\n  }\n}");
export const LIST_MY_ITEMS_FOR_TYPES = gql("query ListMyItemForType($type: String!) {\n  user_item(where: {item: {type: {_eq: $type}}}, order_by: {item_id: asc}) {\n    item_id\n    count\n    item {\n      description\n    }\n  }\n}");
export const RESET_CHARACTER_EQUIPMENT = gql("mutation ResetCharacterEquipment($id: uuid!, $slot: String!) {\n  delete_user_character_equipment_by_pk(id: $id, slot: $slot) {\n    slot\n  }\n}");
export const SET_CHARACTER_EQUIPMENT = gql("mutation SetCharacterEquipment($id: uuid!, $slot: String!, $item_id: String!, $data: jsonb!, $count: Int = 1) {\n  insert_user_character_equipment_one(object: {id: $id, slot: $slot, item_id: $item_id, data: $data, count: $count}, on_conflict: {constraint: user_character_equipment_pkey, update_columns: [item_id, data]}) {\n    slot\n  }\n}");
export const GET_CHARACTER_RENAME_HISTORY = gql('query GetCharacterRenameHistory($id: uuid!) {\n  user_character_rename_history(where: {id: {_eq: $id}, old_name: {_neq: ""}}) {\n    created_at\n  }\n}');
export const CLAIM_FREE_ENERGY = gql("mutation ClaimFreeEnergy {\n  promotion_claim_free_energy {\n    created_at\n  }\n}");
export const PIN_MESSAGE = gql("mutation PinMessage($character_id: uuid!, $data: jsonb!) {\n  pin_message(args: {character_id: $character_id, data: $data}) {\n    value\n  }\n}");
export const LIST_PINNED_MESSAGES = gql('query ListCharacterPinnedMessages($id: uuid!) {\n  user_character_pinned_message(order_by: {created_at: desc}, where: {character_id: {_eq: $id}}) {\n    id\n    data\n    created_at\n  }\n  config(where:{key:{_eq:"default.max_pin_messages"}}) {\n    value\n  }\n}');
export const DELETE_PINNED_MESSAGE = gql("mutation DeletePinnedMessage($id: bigint!) {\n  delete_user_character_pinned_message_by_pk(id: $id) {\n    id\n  }\n}");
export const CHECK_MESSAGE_PINNED = gql("query CheckMessagePinned($character_id: uuid!, $content_digest: uuid!) {\n  user_character_pinned_message(where: {character_id: {_eq: $character_id}, content_digest: {_eq: $content_digest}}) {\n    id\n  }\n}");
export const GET_WORLD_PLAYER_STATE = gql("query GetWorldPlayerState($world: bpchar!) {\n  player(where: {world_id: {_eq: $world}}) {\n    id\n    x\n    y\n    face\n    action\n    plan\n  }\n}");
export const GET_CHARACTER_OWNBER = gql("query GetCharacterOwner($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    owner\n  }\n}");
export const UPDATE_MY_SEASON_WAITLIST_ENTRY = gql("mutation UpdateMyWaitlistEntry($season_id: bpchar!, $character_id: uuid!, $config: jsonb!, $private_config: jsonb) {\n  update_season_waitlist_by_pk(pk_columns: {season_id: $season_id, character_id: $character_id}, _set: {config: $config, private_config: $private_config}) {\n    created_at\n  }\n}");
export const ALTERNATIVE_CHARACTER_MODEL = gql("query AlternativeCharacterModel {\n  alternative_character_model(order_by: {order: asc_nulls_first}) {\n    id\n    description\n  }\n}");
export const CHARACTER_SPRITE_HISTORY = gql("query CharacterSpriteHistory($id: uuid!, $limit: Int!) {\n  user_character_sprite_history(where: {id: {_eq: $id}}, order_by: {created_at: desc}, limit: $limit) {\n    url\n    config\n  }\n}");
export const GET_LATEST_MESSAGE = gql("query GetLatestMessage($channel: uuid!) {\n  messages(limit: 1, order_by: {id: desc}, where: {channel_id: {_eq: $channel}}) {\n    author_id\n    message\n    user_id\n  }\n}");
